import React, { Component } from 'react'

class Reel extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="13" height="13" viewBox="0 0 2 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="13" fill={color}/>
        <rect width="2" height="13" x="-5" y="0" fill={color}/>
        <rect width="2" height="13" x="5" y="0" fill={color}/>
      </svg>
    )
  }
}

class Grid extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="4.8" height="4.8" fill={color}/>
        <rect x="8" width="4.8" height="4.8" fill={color}/>
        <rect y="8" width="4.8" height="4.8" fill={color}/>
        <rect x="8" y="8" width="4.8" height="4.8" fill={color}/>
      </svg>
    )
  }
}

class Play extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9092 5.99991L0.90918 11.909V0.0908203L10.9092 5.99991Z" fill={color}/>
      </svg>
    )
  }
}

class Pause extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="3" height="12" fill={color}/>
        <rect x="6" width="3" height="12" fill={color}/>
      </svg>
    )
  }
}

class Muted extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="466px" height="354px" viewBox="0 0 466 354" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M259.08,31.4 L259.08,322.6 C259.08,348.92 227.721,362.92 208.119,344.998 L110.681,256.521 L30.603,256.521 C13.802,256.521 0.365,243.08 0.365,226.283 L0.361094,127.721 C0.361094,110.92 13.802094,97.483 30.599094,97.483 L110.677094,97.483 L208.681094,9.003 C228.283094,-8.919 259.08,5.0811 259.08,31.401 L259.08,31.4 Z" id="Path"></path>
            <path d="M461.24,230.76 C440.521,210.041 428.201,197.721 407.478,176.998 C428.197,156.279 440.517,143.959 461.24,123.236 C473.002,111.474 454.5212,92.998 442.76,104.756 C422.041,125.475 409.721,137.795 388.998,158.518 C368.279,137.799 355.959,125.479 335.236,104.756 C323.474,92.994 304.998,111.4748 316.756,123.236 L370.518,176.998 C349.799,197.717 337.479,210.037 316.756,230.76 C304.994,242.522 323.4748,260.998 335.236,249.24 L388.998,195.478 C409.717,216.197 422.037,228.517 442.76,249.24 C454.522,260.998 473.002,242.5173 461.24,230.76 L461.24,230.76 Z"></path>
          </g>
        </g>
      </svg>
    )
  }
}

class Mute extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="260px" height="354px" viewBox="0 0 260 354" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M259.08,31.4 L259.08,322.6 C259.08,348.92 227.721,362.92 208.119,344.998 L110.681,256.521 L30.603,256.521 C13.802,256.521 0.365,243.08 0.365,226.283 L0.361094,127.721 C0.361094,110.92 13.802094,97.483 30.599094,97.483 L110.677094,97.483 L208.681094,9.003 C228.283094,-8.919 259.08,5.0811 259.08,31.401 L259.08,31.4 Z" id="Path"></path>
          </g>
        </g>
      </svg>
    )
  }
}

export { Reel, Grid, Play, Pause, Mute, Muted }
