import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Play, Pause, Mute, Muted } from '../components/icons'

class ProjectTemplate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      info: false,
      playing: true,
      muted: true,
      slideIndex: 0,
      width: parseInt((1/this.props.data.wpPost.acf?.gallery?.length) * 100) + '%'
    }

    this.videoRef = React.createRef()
    this.timer = [React.createRef(), React.createRef()]
    this.timerInner = [React.createRef(), React.createRef()]
    this.index = this.props.data.allWpPost.nodes.findIndex(el => el.id === this.props.data.wpPost.id) + 1
  }

  componentDidMount() {
    this.videoRef?.current?.addEventListener('timeupdate', this.setTime);
  }

  componentWillUnmount() {
    this.videoRef?.current?.removeEventListener('timeupdate', this.setTime);
  }

  setTime = () => {
    if (!document || !this.videoRef) return
    for (let i = 0; i < 2; i++) {
      let barLength = this.timer[i].current.clientWidth * (this.videoRef.current.currentTime/this.videoRef.current.duration);
      this.timerInner[i].current.style.width = barLength + 'px';
    }
  }

  updateTime = (event) => {
    event.preventDefault()  
    if (!this?.videoRef?.current) return
    const rect = event.currentTarget.getBoundingClientRect();
    const perc = (event.clientX - rect.left) / rect.width; //x position within the element.
    this.videoRef.current.currentTime = this.videoRef?.current?.duration * perc
    this.setTime()
  }

  togglePlaying = () => {
    if (this.videoRef.current.paused) {
      this.videoRef.current.play()
      this.setState({ playing: true })
    } else {
      this.videoRef.current.pause()
      this.setState({ playing: false })
    }
  }

  render() {
    let page = this.props.data.wpPost
    let { info, playing, muted, slideIndex, width } = this.state

    const settings = {
      speed: 600,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      centerMode: true,
      centerPadding: '100px',
      pauseOnHover: false,
      focusOnSelect: false,
      beforeChange: (current, next) => {
        this.setState({ 
          slideIndex: next,
          width: Number(((next + 1) / page.acf.gallery.length) * 100) + '%'
        })
      },
      responsive: [
        {
          breakpoint: 900,
          settings: {
            centerPadding: '20px',
          }
        },
      ],
    }

    return (
      <>
        <Seo
          path={page.slug === "home" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={`single-project`}
        />
        <article className={`project ${info ? 'project--active' : ''}`}>
          { page.acf?.video &&
            <section className='project__video'>
              <video ref={this.videoRef} autoPlay playsInline muted={muted} loop>
                <source src={page.acf.video} type='video/mp4' />
              </video>
            </section>
          }
          { !page.acf?.video && page.acf?.gallery?.length > 0 &&
            <section className='project__gallery'>
              { page.acf.gallery?.length > 0 &&
                <Slider {...settings}>
                { page.acf.gallery.map((el, i) => (
                  <div className='project__gallery-item' key={i} >
                    { el.localFile && <GatsbyImage image={getImage(el.localFile)} alt={el.alt} /> }
                  </div>
                )) }
                </Slider>
              }
            </section>
          }
          <section className={`project__info ${info && 'project__info--active'}`}>
            <div className='project__titles'>
              { !page.acf?.video && page.acf?.gallery?.length > 0 &&
                <div className='project__controls project__controls--mobile'>
                  <span className='project__gallery-counter'>{slideIndex + 1}/{page.acf.gallery.length}</span>
                  <div className='project__timer'>
                    <div style={{ width: width }} />
                  </div>
                </div>
              }
              { page.acf?.video && 
                <div className='project__controls project__controls--mobile'>
                  <button type='button' className='project__pause' onClick={this.togglePlaying}>
                    { playing ? <Pause color={`#FFFFFF`} /> : <Play color={`#FFFFFF`} /> }
                  </button>
                  <button type='button' className='project__mute' onClick={() => this.setState({ muted: !muted })}>
                    { muted ? <Muted color={`#FFFFFF`} /> : <Mute color={`#FFFFFF`} /> }
                  </button>
                  <div ref={this.timer[0]} className='project__timer' onClick={this.updateTime}>
                    <div ref={this.timerInner[0]} />
                  </div>
                </div>
              }
              <div className='project__left'>
                <span>{ page.acf?.video ? 'Now Playing' : 'Gallery' }</span>
              </div>
              <div className='project__center'>
                <div className='project__name'>
                  <h1><span>{ this.index < 10 && '0'}{ this.index }</span> {page.title}</h1>
                  <span>{page.acf.tag}</span>
                </div>
                { page.acf?.video && 
                  <div className='project__controls project__controls--desktop'>
                    <button type='button' className='project__pause' onClick={this.togglePlaying}>
                      { playing ? <Pause color={`#FFFFFF`} /> : <Play color={`#FFFFFF`} /> }
                    </button>
                    <button type='button' className='project__mute' onClick={() => this.setState({ muted: !muted })}>
                      { muted ? <Muted color={`#FFFFFF`} /> : <Mute color={`#FFFFFF`} /> }
                    </button>
                    <div ref={this.timer[1]} className='project__timer' onClick={this.updateTime}>
                      <div ref={this.timerInner[1]} />
                    </div>
                  </div>
                }
                { !page.acf?.video && page.acf?.gallery?.length > 0 &&
                  <div className='project__controls project__controls--desktop'>
                    <span className='project__gallery-counter'>{slideIndex + 1}/{page.acf.gallery.length}</span>
                    <div className='project__timer'>
                      <div style={{ width: width }} />
                    </div>
                  </div>
                }
              </div>
              <div className='project__right'>
                <button
                  type='button'
                  onClick={() => this.setState({ info: !info })}
                >
                  {!info ? 'Project Info' : 'Close'} <span className={`icon ${info && 'icon--close'}`} />
                </button>
              </div>
            </div>
            <div className='project__content'>
              <div className='project__left'>
                &nbsp;
              </div>
              <div className='project__center'>
                <div className='project__name'>
                  <div className='project__description' dangerouslySetInnerHTML={{ __html: page.content }} />
                </div>
              </div>
              <div className='project__right'>
                &nbsp;
              </div>
            </div>
          </section>
        </article>
        <button className='project__close' onClick={() => navigate(-1)}>Close <span className='lines'></span></button>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    allWpPost {
      nodes {
        id
      }
    }
    wpPost(id: {eq: $id}) {
      id
      title
      slug
      content
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      acf {
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 1200
                formats: [AUTO, WEBP, AVIF]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        video
        tag
      }
    }
  }
`

export default ProjectTemplate
